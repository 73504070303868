import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { searchFormId } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import SearchIcon from '@iconscout/unicons/svg/line/search.svg'

const ButtonSearch: FC = () => {
  const { t } = useTranslation()

  const scrollToTop = () => {
    document.getElementById(searchFormId)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <Container>
      <Button form={searchFormId} htmlType="submit" onClick={scrollToTop} type="primary">
        <SearchIcon />
        {t('common.searchTrains')}
      </Button>
    </Container>
  )
}

const Container = styled.div`
  button {
    max-width: 256px;
    height: 56px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    width: 100%;
    background: transparent;
    padding: 30px 20px 0 20px;

    button {
      max-width: 100%;
      width: 100%;
    }
  }
`

export default ButtonSearch
